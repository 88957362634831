import { Link } from "react-router-dom";
import { Col, Drawer, Row } from "antd";
import Container from "react-strap-grid/container";
import { withRouter } from "react-router";
import { MenuOutlined } from "@ant-design/icons";

import { visibleRoutes } from "../../configs/routes";
import logo from "assets/img/logo-light.png";
import logoFilmnet from "assets/img/logo.svg";
import "./styles.css";
import { useState } from "react";

function Header() {
  const [visible, setvisible] = useState(false);

  const onClose = () => {
    setvisible(false);
  };

  const menu = (
    <ul className="headerMenu">
      {visibleRoutes({ visibleIn: ["header"] }).map((route, index) => (
        <li key={index}>
          <Link
            className="header__menu-item"
            exact={route.exact}
            key={index}
            to={route.path}
          >
            {route.title}
          </Link>
        </li>
      ))}
    </ul>
  );
  return (
    <header className="header">
      <div className="header__bg" />
      <Container fluid>
        <Row align="middle" justify="space-between" align="middle">
          <Col
            xs={{ span: 4, order: 2 }}
            sm={{ span: 4, order: 2 }}
            md={{ span: 2, order: 2 }}
            lg={{ span: 2, order: 2 }}
            xl={{ span: 2, order: 1 }}
          >
            <div className="logoHeader">
              <Link to="/">
                <img src={logo} alt="band bazi" />
              </Link>
            </div>
          </Col>
          <Col xs={0} sm={0} md={0} lg={0} xl={{ span: 20, order: 2 }}>
            {menu}
          </Col>
          <Col
            xs={{ span: 3, order: 1 }}
            sm={{ span: 3, order: 1 }}
            md={{ span: 2, order: 1 }}
            lg={{ span: 2, order: 1 }}
            xl={0}
          >
            <MenuOutlined
              style={{ color: "#fff", fontSize: 30 }}
              onClick={() => setvisible(true)}
            />
          </Col>

          <Col
            xs={{ span: 4, order: 3, offset: 11 }}
            sm={{ span: 4, order: 3, offset: 11 }}
            md={{ span: 3, order: 3, offset: 17 }}
            lg={{ span: 3, order: 3, offset: 17 }}
            xl={{ span: 2, order: 3, offset: 0 }}
          >
            <a href="https://filmnet.ir/" className={"filmnetLogo"}>
              <img src={logoFilmnet} alt="filmnet" />
            </a>
          </Col>
        </Row>
      </Container>

      <Drawer
        title={null}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        {menu}
      </Drawer>
    </header>
  );
}

export default withRouter(Header);
