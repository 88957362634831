import { lazy } from "react";

const routes = [
  {
    title: "خانه",
    path: "/",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/home")),
  },
  {
    title: "اخبار",
    path: "/blog",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/blog")),
  },
  {
    title: "گالری",
    path: "/gallery",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/gallery")),
  },
  {
    title: "گالری",
    path: "/gallery/:id",
    exact: true,
    visibleIn: [],
    component: lazy(() => import("../pages/gallery-post")),
  },
  {
    title: "ثبت‌نام",
    path: "/register",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/register")),
  },
  {
    title: "ثبت‌نام تماشاگران",
    path: "/register/audience/information",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/user")),
  },
  {
    title: "درباره بندبازی",
    path: "/aboutus",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/about")),
  },

  {
    title: "ارتباط با ما",
    path: "/ContactUs",
    exact: true,
    visibleIn: ["header", "footer"],
    component: lazy(() => import("../pages/contactUs")),
  },

  {
    title: "بلاگ",
    path: "/blog/post/:id",
    exact: true,
    visibleIn: [],
    component: lazy(() => import("../pages/post")),
  },
  {
    title: "ثبت اطلاعات تماشاگرام",
    path: "/register/audience/:id/information",
    exact: true,
    visibleIn: [],
    component: lazy(() => import("../pages/user")),
  },
];
export default routes;

export const visibleRoutes = ({ visibleIn } = {}) =>
  routes.filter((route) => {
    if (visibleIn) {
      return route.visibleIn.find((visible) => visible === visibleIn[0]);
    } else {
      return route.visibleIn.length > 0;
    }
  });
