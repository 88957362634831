import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/style/bootstrap-grid-rtl.min.css";
import { ConfigProvider } from "antd";
import fa_IR from "antd/lib/locale-provider/fa_IR";
import "./index.css";

ReactDOM.render(
  <ConfigProvider locale={fa_IR} direction="rtl">
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
