import React from "react";

// import { visibleRoutes } from "../../configs/routes";
import Container from "react-strap-grid/container";
import Row from "react-strap-grid/row";
import Col from "react-strap-grid/col";
import imgLogo from "../../assets/images/logo.png";

import Instagram from "assets/images/instagram.svg";
import Twitter from "assets/images/twitter.svg";
import Aparat from "assets/images/aparat.svg";
import youtube from "assets/images/youtube.svg";

import "./styles.css";

function Footer() {
  const social = [
    { img: Instagram, link: "https://www.instagram.com/bandbazi_official/" },
    { img: youtube, link: "!#" },
    { img: Twitter, link: "https://twitter.com/bandbazi_official" },
    { img: Aparat, link: "https://www.aparat.com/bandbazi_official" },
  ];

  return (
    <>
      <footer className="footer">
        <div className="footer__rotate-bg" />
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={8}>
              <div className="footer__inner-container d-flex flex-column align-items-center">
                <div className="footer__inner-container__description d-flex flex-column align-items-center">
                  <p className="footer__inner-container__description-text">
                    بندبازی رقابتی برای گروه‌های موسیقی پاپ و پاپ-تلفیقی است.
                    <br />
                    استفاده از مطالب وب‌سایت با ذکر منبع بلامانع است.
                  </p>
                </div>
                <p className="footer__inner-container__copyright">
                  تمام حقوق مادی و معنوی این وب‌سایت متعلق به برنامه‌ی بندبازی
                  است.
                </p>
              </div>

              <div className="footer__inner-container__socialNetwork">
                <ul>
                  {social.map((item, index) => (
                    <li key={index}>
                      <a href={item.link} rel="noreferrer">
                        <img src={item.img} alt="" />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
